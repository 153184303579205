import React from 'react'
import './index.scss'
import imgMoonshotLogo from '../../assets/moonshot-logo.svg'

export default function SystemDown (props) {
  return (
    <div className='page-system-down'>
      <div><img src={imgMoonshotLogo} /></div>

      <h3>The Dashboard system is being updated.</h3>
    </div>
  )
}
